import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link as GatsbyLink } from "gatsby"
import Layout from "../../components/Layouts/Layout"
import {
    BlogListPage, BlogListVertical,
    HeroAngleRightImage as Hero,
    FeatureAlternativeSideImage as Feature,
    TestimonialImageOverlapping as Testimonial,
    BlogSectionThreeColumns as BlogSection,
    StatsCard as Stats,
    LogoCloudSimpleBranded as LogoCloud,
    ContactSimpleTwoColumns as Contact,
    modelFactory
} from "@domenicosolazzo/site-designsystem"
import { DataBlogPage as PageData } from "../../data"

import ErrorBoundary from "../../errors/ErrorHandler"
import { blogSectionParser } from "../../utils/blog/blogParser"

const generateEdgeBlogItemNode = (edge) => {
  const item = edge.node;
  const {
    frontmatter
  } = item

  const blogItem = modelFactory.getModel({type: "blog_item", options: {
    contentId: item.id,
    title: modelFactory.getModel({type: "text", options: {
      text: item.title
    }}),
    date: modelFactory.getModel({type: "text", options: {
      text: frontmatter?.datePublished
    }}),
    excerpt: "",
    description: "",
    draft: "",
    coverImage: {
      image: "",
      alt: ""
    }
  }})
  return blogItem
}
export default function BlogPage() {
    const data = useStaticQuery(graphql`
        query {
          allMdx(sort: {order: DESC, fields: frontmatter___date}) {
            edges {
              node {
                excerpt
                fileAbsolutePath
                frontmatter {
                  topic
                  title
                  tags
                  path
                  datePublished
                  authorName
                  date
                  description
                  draft
                  coverImageAlt
                  coverImage
                }
                id
                slug
                timeToRead
                wordCount {
                  words
                  sentences
                  paragraphs
                }
                body
              }
            }
            max(field: timeToRead)
            min(field: timeToRead)
            totalCount
            pageInfo {
              currentPage
              hasNextPage
              hasPreviousPage
              itemCount
              pageCount
              perPage
              totalCount
            }
          }
        }
    `)

    const {
      hero,
      logoCloud,
      contact,
    } = PageData
    
    const blogSectionData =  blogSectionParser(data)
  
    const blogSection = {
      ...blogSectionData
    }
    return (
        <Layout>
          <Hero {...hero} />
          <BlogSection {...blogSection} />
          <LogoCloud {...logoCloud} />
          <Contact {...contact} />
        </Layout>
    )
}